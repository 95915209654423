<template>
  <div>
    <!-- <Marketing /> -->
    <!-- asdasdas -->
    <!-- <Dashboard /> -->
    <div class="zoomdashboard">
      <div>
        <div class="homeleftinformation">
          <div>
            <img :src="require('@/assets/img/logo-login.png')" />
            <h3 style="font-weight: ">
              {{ greeting() }}

              <img
                :src="require('@/assets/img/icon/nighticon.svg')"
                v-if="greeting() == 'Selamat Malam '"
                style="width: 35px"
              />

              <img
                :src="require('@/assets/img/icon/morningicon.svg')"
                v-else
                style="width: 40px"
              />
            </h3>
            <div>
              <div class="nameuser">
                <h1>
                  {{ profile.user.nama_lengkap.split(" ")[0] }}
                  {{
                    profile.user.nama_lengkap.split(" ")[1] != undefined
                      ? profile.user.nama_lengkap.split(" ")[1]
                      : ""
                  }}
                </h1>
                <div style="display: flex">
                  <router-link :to="{ name: 'ProfileUser' }"
                    ><img :src="require('@/assets/img/icon/setting.svg')"
                  /></router-link>
                  <div class="notifhome">
                    <Notif />
                  </div>
                </div>
              </div>
            </div>

            <div class="dashboardpt">
              <router-link to="#">
                <span
                  ><span style="text-transform: capitalize">{{
                    perusahaan.nama_perusahaan
                  }}</span>
                  <a-icon type="right"
                /></span>

                <div class="topoverlayperusahaan">
                  <a-input-search
                    placeholder="Cari"
                    @change="onLeftSearch"
                    allowClear
                  />
                  <simplebar
                    class="simplebarcustom"
                    data-simplebar-auto-hide="false"
                    style="max-height: 150px"
                  >
                    <div>
                      <router-link
                        class="simpleklikPT"
                        to="#"
                        @click.native="changePT('all', 'all', 'all', 'all')"
                      >
                        All
                      </router-link>
                    </div>
                    <div v-for="(item, i) in FilterSearch" :key="i">
                      <router-link
                        class="simpleklikPT"
                        to="#"
                        @click.native="
                          changePT(
                            item.id_job_order_perusahaan,
                            item.nama_perusahaan,
                            item.id_contact_perusahaan,
                            item.benefit_existing
                          )
                        "
                      >
                        {{ item.nama_perusahaan }}
                      </router-link>
                    </div>
                  </simplebar>
                </div>
              </router-link>
            </div>

            <div class="_thr homeplan">
              <label>Plan</label>

              <div>
                <div>
                  <ul v-if="perusahaan.benefit_existing != 'all'">
                    <li
                      v-for="(item, i) in JSON.parse(
                        perusahaan.benefit_existing
                      )"
                      :key="i"
                      @click="Information(item)"
                    >
                      <img
                        :src="
                          require('@/assets/img/icon/' +
                            item.replace(/\s/g, '').toLowerCase() +
                            '.svg')
                        "
                      />
                      {{ item }}
                    </li>
                  </ul>

                  <ul v-else>
                    <!-- <li>
                      {{
                        profile.perusahaan
                          .map((key) =>
                            JSON.parse(key.benefit_existing).toString()
                          )
                          .filter(onlyUnique)
                          .toString()
                          .split(",")
                      }}
                    </li> -->
                    <li
                      v-for="(item, i) in getAllPlan"
                      :key="i"
                      @click="Information(item)"
                    >
                      <img
                        :src="
                          require('@/assets/img/icon/' +
                            item.replace(/\s/g, '').toLowerCase() +
                            '.svg')
                        "
                      />
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="zoomcarddashboard">
          <div>
            <div class="_dashboard">
              <ClaimPending>
                <template slot="titledsahboard">
                  <div
                    @click="dashboardclick('Klaim Pending', perusahaan)"
                    style="
                      background: rgb(238 88 67 / 94%) !important;
                      color: #fff;
                    "
                  >
                    <img
                      :src="require('@/assets/img/icon/pdg.svg')"
                      style="filter: brightness(0) invert(1)"
                    />
                    <h3 style="color: #fff">{{ totalPending }}</h3>
                    <div>Klaim Pending</div>
                  </div>
                </template>

                <template slot="sortingdashboard">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <a
                      class="ant-dropdown-link"
                      @click="(e) => e.preventDefault()"
                    >
                      <img
                        :src="require('@/assets/img/icon/sortup.svg')"
                        :style="
                          clickPending == 1 ? '' : 'filter: grayscale(100%)'
                        "
                      />
                      <img
                        :src="require('@/assets/img/icon/sortdown.svg')"
                        :style="
                          clickPending == 2 ? '' : 'filter: grayscale(100%)'
                        "
                      />
                    </a>

                    <div slot="overlay" class="dropdownoverlay">
                      <!-- tanggal masuk, tanggal keluar, perubahaan status -->
                      <ul>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByPending('Tanggal Masuk', 'tanggal_masuk')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickPending == 1 &&
                                sortByPendinglabel == 'Tanggal Masuk'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickPending == 2 &&
                                sortByPendinglabel == 'Tanggal Masuk'
                              "
                            />

                            Tanggal Masuk</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByPending('Tanggal Keluar', 'tanggal_keluar')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickPending == 1 &&
                                sortByPendinglabel == 'Tanggal Keluar'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickPending == 2 &&
                                sortByPendinglabel == 'Tanggal Keluar'
                              "
                            />
                            Tanggal Keluar</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByPending('Status Claim', 'status_claim')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickPending == 1 &&
                                sortByPendinglabel == 'Status Claim'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickPending == 2 &&
                                sortByPendinglabel == 'Status Claim'
                              "
                            />
                            Status Claim</router-link
                          >
                        </li>

                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByPending('Created', 'created_at')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickPending == 1 &&
                                sortByPendinglabel == 'Created'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickPending == 2 &&
                                sortByPendinglabel == 'Created'
                              "
                            />
                            Created</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </a-dropdown>
                </template>

                <template slot="table">
                  <a-table
                    :rowKey="(record) => record.id"
                    :columns="columns"
                    :dataSource="dataPending"
                    :pagination="false"
                    :loading="loading1"
                    :scroll="
                      widthtable >= 602
                        ? { y: heighttable }
                        : { x: widthtable, y: heighttable }
                    "
                    class="dashboardtable"
                    @change="handleTableChangePending"
                  >
                    <template slot="product"> Product<br />Plan </template>
                    <template slot="jumlah_diajukan">
                      Jumlah yang<br />Diajukan
                    </template>
                    <template slot="no_claim" slot-scope="text, record">
                      <router-link
                        :to="{
                          name: 'DetailKlaim',
                          params: { id: record.id },
                        }"
                        >{{ text }}
                        <a-icon
                          type="right"
                          style="
                            font-size: 11px !important;
                            font-weight: 500;
                            padding-left: 10px;
                          "
                      /></router-link>
                    </template>

                    <template slot="nama_pasien" slot-scope="text, record">
                      <div :title="text">
                        <router-link
                          :to="{
                            name: 'DetailPeserta',
                            params: {
                              id: record.id_job_order,
                              idpeserta: record.id_peserta,
                            },
                          }"
                        >
                          <span
                            style="
                              white-space: nowrap;
                              display: inline-block;
                              width: 100px;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                            >{{ text }}</span
                          >
                          <a-icon
                            type="right"
                            style="
                              font-size: 11px !important;
                              font-weight: bolder !important;
                              padding-left: 10px;
                              vertical-align: text-top;
                            "
                          />
                        </router-link>
                      </div>
                    </template>

                    <template slot="jumlah_diajukan" slot-scope="text, record">
                      <div v-if="text == null">-</div>
                      <div v-else>
                        <div style="color: #060d17">
                          {{ record.mata_uang }} {{ numberWithCommas(text) }}
                        </div>
                      </div>
                    </template>
                    <template slot="produk_plan" slot-scope="text">
                      <div
                        class="iconpeserta"
                        v-if="text != 'NON PLAN/PRODUCT'"
                      >
                        <img
                          :src="
                            require(`@/assets/img/icon/${text
                              .replace(/\s/g, '')
                              .toLowerCase()}.svg`)
                          "
                        />
                        {{ text }}
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template slot="footer">
                      {{ null }}
                    </template>
                  </a-table>
                </template>
              </ClaimPending>
            </div>
            <div class="_dashboard" ref="KlaimProses">
              <ClaimPending>
                <template slot="titledsahboard">
                  <div
                    @click="dashboardclick('Klaim Proses', perusahaan)"
                    style="
                      background: rgb(76 136 255 / 83%) !important;
                      color: #fff;
                    "
                  >
                    <img
                      :src="require('@/assets/img/icon/sdoa.svg')"
                      style="filter: brightness(0) invert(1)"
                    />
                    <h3 style="color: #fff">{{ totalKlaimProses }}</h3>
                    <div>Klaim Sedang di-review Asuransi</div>
                  </div>
                </template>
                <template slot="sortingdashboard">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <a
                      class="ant-dropdown-link"
                      @click="(e) => e.preventDefault()"
                    >
                      <img
                        :src="require('@/assets/img/icon/sortup.svg')"
                        :style="
                          clickProses == 1 ? '' : 'filter: grayscale(100%)'
                        "
                      />
                      <img
                        :src="require('@/assets/img/icon/sortdown.svg')"
                        :style="
                          clickProses == 2 ? '' : 'filter: grayscale(100%)'
                        "
                      />
                    </a>

                    <div slot="overlay" class="dropdownoverlay">
                      <!-- tanggal masuk, tanggal keluar, perubahaan status -->
                      <ul>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByProses('Tanggal Masuk', 'tanggal_masuk')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickProses == 1 &&
                                sortByProseslabel == 'Tanggal Masuk'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickProses == 2 &&
                                sortByProseslabel == 'Tanggal Masuk'
                              "
                            />
                            Tanggal Masuk</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByProses('Tanggal Keluar', 'tanggal_keluar')
                            "
                            ><img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickProses == 1 &&
                                sortByProseslabel == 'Tanggal Keluar'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickProses == 2 &&
                                sortByProseslabel == 'Tanggal Keluar'
                              "
                            />
                            Tanggal Keluar</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByProses('Status Claim', 'status_claim')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickProses == 1 &&
                                sortByProseslabel == 'Status Claim'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickProses == 2 &&
                                sortByProseslabel == 'Status Claim'
                              "
                            />
                            Status Claim</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByProses('Created', 'status_claim')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickProses == 1 &&
                                sortByProseslabel == 'Created'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickProses == 2 &&
                                sortByProseslabel == 'Created'
                              "
                            />
                            Created</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </a-dropdown>
                </template>

                <template slot="table">
                  <a-table
                    :rowKey="(record) => record.id"
                    :columns="columns"
                    :dataSource="data"
                    :pagination="false"
                    :loading="loading3"
                    :scroll="
                      widthtable >= 602
                        ? { y: heighttable }
                        : { x: widthtable, y: heighttable }
                    "
                    class="dashboardtable"
                    @change="handleTableChangeProses"
                  >
                    <template slot="product"> Product<br />Plan </template>
                    <template slot="jumlah_diajukan">
                      Jumlah yang<br />Diajukan
                    </template>
                    <template slot="no_claim" slot-scope="text, record">
                      <router-link
                        :to="{ name: 'DetailKlaim', params: { id: record.id } }"
                        >{{ text }}
                        <a-icon
                          type="right"
                          style="
                            font-size: 11px !important;
                            font-weight: 500;
                            padding-left: 10px;
                          "
                      /></router-link>
                    </template>

                    <template slot="nama_pasien" slot-scope="text, record">
                      <div :title="text">
                        <router-link
                          :to="{
                            name: 'DetailPeserta',
                            params: {
                              id: record.id_job_order,
                              idpeserta: record.id_peserta,
                            },
                          }"
                        >
                          <span
                            style="
                              white-space: nowrap;
                              display: inline-block;
                              width: 100px;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                            >{{ text }}</span
                          >
                          <a-icon
                            type="right"
                            style="
                              font-size: 11px !important;
                              font-weight: bolder !important;
                              padding-left: 10px;
                              vertical-align: text-top;
                            "
                          />
                        </router-link>
                      </div>
                    </template>

                    <template slot="jumlah_diajukan" slot-scope="text, record">
                      <div v-if="text == null">-</div>
                      <div v-else>
                        <div style="color: #060d17">
                          {{ record.mata_uang }} {{ numberWithCommas(text) }}
                        </div>
                      </div>
                    </template>
                    <template slot="produk_plan" slot-scope="text">
                      <div
                        class="iconpeserta"
                        v-if="text != 'NON PLAN/PRODUCT'"
                      >
                        <img
                          :src="
                            require(`@/assets/img/icon/${text
                              .replace(/\s/g, '')
                              .toLowerCase()}.svg`)
                          "
                        />
                        {{ text }}
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template slot="footer">
                      {{ null }}
                    </template>
                  </a-table>
                </template>
              </ClaimPending>
            </div>
          </div>
          <div>
            <div class="_dashboard">
              <ClaimPending>
                <template slot="titledsahboard">
                  <div
                    @click="dashboardclick('Klaim Diproses Ulang', perusahaan)"
                    style="
                      background: rgb(255 153 0 / 81%) !important;
                      color: #fff;
                    "
                  >
                    <img
                      :src="require('@/assets/img/icon/mlk.svg')"
                      style="filter: brightness(0) invert(1)"
                    />
                    <h3 style="color: #fff">{{ totalPUCL }}</h3>
                    <div>Klaim Kekurangan Dokumen</div>
                  </div>
                </template>
                <template slot="sortingdashboard">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <a
                      class="ant-dropdown-link"
                      @click="(e) => e.preventDefault()"
                    >
                      <img
                        :src="require('@/assets/img/icon/sortup.svg')"
                        :style="clickPUCL == 1 ? '' : 'filter: grayscale(100%)'"
                      />
                      <img
                        :src="require('@/assets/img/icon/sortdown.svg')"
                        :style="clickPUCL == 2 ? '' : 'filter: grayscale(100%)'"
                      />
                    </a>

                    <div slot="overlay" class="dropdownoverlay">
                      <!-- tanggal masuk, tanggal keluar, perubahaan status -->
                      <ul>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByPUCL('Tanggal Masuk', 'tanggal_masuk')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickPUCL == 1 &&
                                sortByPUCLlabel == 'Tanggal Masuk'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickPUCL == 2 &&
                                sortByPUCLlabel == 'Tanggal Masuk'
                              "
                            />
                            Tanggal Masuk</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByPUCL('Tanggal Keluar', 'tanggal_keluar')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickPUCL == 1 &&
                                sortByPUCLlabel == 'Tanggal Keluar'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickPUCL == 2 &&
                                sortByPUCLlabel == 'Tanggal Keluar'
                              "
                            />Tanggal Keluar</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByPUCL('Status Claim', 'status_claim')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickPUCL == 1 &&
                                sortByPUCLlabel == 'Status Claim'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickPUCL == 2 &&
                                sortByPUCLlabel == 'Status Claim'
                              "
                            />
                            Status Claim</router-link
                          >
                        </li>

                        <li>
                          <router-link
                            to="#"
                            @click.native="
                              sortByPUCL('Created', 'status_claim')
                            "
                          >
                            <img
                              :src="require('@/assets/img/icon/sortup.svg')"
                              v-if="
                                clickPUCL == 1 && sortByPUCLlabel == 'Created'
                              "
                            />
                            <img
                              :src="require('@/assets/img/icon/sortdown.svg')"
                              v-if="
                                clickPUCL == 2 && sortByPUCLlabel == 'Created'
                              "
                            />
                            Created</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </a-dropdown>
                </template>

                <template slot="table">
                  <a-table
                    :rowKey="(record) => record.id"
                    :columns="columns"
                    :dataSource="dataPucl"
                    :pagination="false"
                    :loading="loading2"
                    :scroll="
                      widthtable >= 602
                        ? { y: heighttable }
                        : { x: widthtable, y: heighttable }
                    "
                    class="dashboardtable"
                    @change="handleTableChangePucl"
                  >
                    <template slot="product"> Product<br />Plan </template>
                    <template slot="jumlah_diajukan">
                      Jumlah yang<br />Diajukan
                    </template>
                    <template slot="no_claim" slot-scope="text, record">
                      <router-link
                        :to="{
                          name: 'DetailKlaim',
                          params: { id: record.id },
                        }"
                        >{{ text }}
                        <a-icon
                          type="right"
                          style="
                            font-size: 11px !important;
                            font-weight: 500;
                            padding-left: 10px;
                          "
                      /></router-link>
                    </template>

                    <template slot="nama_pasien" slot-scope="text, record">
                      <div :title="text">
                        <router-link
                          :to="{
                            name: 'DetailPeserta',
                            params: {
                              id: record.id_job_order,
                              idpeserta: record.id_peserta,
                            },
                          }"
                        >
                          <span
                            style="
                              white-space: nowrap;
                              display: inline-block;
                              width: 100px;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                            >{{ text }}</span
                          >
                          <a-icon
                            type="right"
                            style="
                              font-size: 11px !important;
                              font-weight: bolder !important;
                              padding-left: 10px;
                              vertical-align: text-top;
                            "
                          />
                        </router-link>
                      </div>
                    </template>

                    <template slot="jumlah_diajukan" slot-scope="text, record">
                      <div v-if="text == null">-</div>
                      <div v-else>
                        <div style="color: #060d17">
                          {{ record.mata_uang }} {{ numberWithCommas(text) }}
                        </div>
                      </div>
                    </template>
                    <template slot="produk_plan" slot-scope="text">
                      <div
                        class="iconpeserta"
                        v-if="text != 'NON PLAN/PRODUCT'"
                      >
                        <img
                          :src="
                            require(`@/assets/img/icon/${text
                              .replace(/\s/g, '')
                              .toLowerCase()}.svg`)
                          "
                        />
                        {{ text }}
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template slot="footer">
                      {{ null }}
                    </template>
                  </a-table>
                </template>
              </ClaimPending>
            </div>

            <!-- end klaim proses ulang -->

            <div class="zoomperubaanperbulan">
              <div
                class="dashboarddmtop"
                style="overflow: hidden"
                @click="Movementclick('Perbulan', perusahaan)"
              >
                <h2>Perubahan Perbulan</h2>
                <a-spin :spinning="loading4">
                  <Bar
                    :chart-data="dataChart"
                    :chart-options="options"
                    :height="230"
                  />
                </a-spin>
              </div>

              <div class="dashboarddm">
                <div
                  class="dashboarddmbottom"
                  @click="Movementclick('Addition', perusahaan)"
                >
                  <img :src="require('@/assets/img/icon/addition.svg')" />
                  <h3>
                    <a-spin :spinning="loading4">{{ today.addition }} </a-spin>
                  </h3>
                  <div>Addition</div>
                </div>

                <div
                  class="dashboarddmbottom"
                  @click="Movementclick('Deleted', perusahaan)"
                >
                  <img :src="require('@/assets/img/icon/deleted.svg')" />
                  <h3>
                    <a-spin :spinning="loading4">{{ today.deleted }}</a-spin>
                  </h3>
                  <div>Deleted</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Plan ref="plan" />
  </div>
</template>
<script>
//import Bar from "@/components/Dashboard/bar.js";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import SortUp from "@/assets/img/icon/sortup.svg";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
// import Marketing from "@/components/Dashboard/marketing";

import Plan from "@/components/Modal/plan.vue";
import Notif from "@/components/notifications/notif.vue";
import ClaimPending from "@/components/Dashboard/claim.vue";

const columns = [
  {
    title: "Nama Pasien",
    dataIndex: "nama_peserta",
    scopedSlots: { customRender: "nama_pasien" },
    sorter: true,

    fixed: "left",
    width: 150,
  },
  {
    title: "No Claim",
    dataIndex: "no_claim",
    scopedSlots: { customRender: "no_claim" },
    sorter: true,
    width: 190,
  },

  {
    slots: { title: "product" },
    dataIndex: "produk",
    scopedSlots: { customRender: "produk_plan" },
    sorter: true,
    width: 100,
  },

  {
    slots: { title: "jumlah_diajukan" },
    dataIndex: "jumlah_diajukan",
    scopedSlots: { customRender: "jumlah_diajukan" },
    sorter: true,
    width: 120,
  },
];

const options = {
  borderRadius: 30,
  aspectRatio: 2,
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      display: true,

      ticks: {
        display: false,
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        color: (context) => {
          if (context.tick.value === 0) {
            return "#D8DDEC";
          }
        },
        tickLength: 0,
      },
    },

    x: {
      display: true,
      ticks: {
        display: true,
        beginAtZero: true,
      },

      grid: {
        tickLength: 1,
        color: "rgb(216 221 236)",
        drawOnChartArea: false,

        drawTicks: true,
      },
    },
  },
};
export default {
  components: {
    Notif,
    ClaimPending,
    Bar,
    Plan,
    simplebar,
  },
  data() {
    return {
      columns,
      data: [],
      dataChart: {},
      dataPending: [],
      dataPucl: [],
      today: {},
      options,
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      sortByPendinglabel: "Created",
      clickPending: 1,
      sortByProseslabel: "Created",
      clickProses: 1,
      sortByPUCLlabel: "Created",
      clickPUCL: 1,
      heighttable: 0,
      widthtable: 0,
      perusahaan: "",
      totalPending: 0,
      totalPUCL: 0,
      totalKlaimProses: 0,
      search: "",
      loadpage: false,
    };
  },

  computed: {
    getAllPlan() {
      return this.$store.state.Auth.data.perusahaan
        .map((key) => JSON.parse(key.benefit_existing).toString())
        .filter(this.onlyUnique)
        .toString()
        .split(",")
        .filter(this.onlyUnique);
    },
    group() {
      return this.$store.state.Auth.data.group[0];
    },
    profile() {
      return this.$store.state.Auth.data;
    },

    FilterSearch() {
      // profile.perusahaan.filter(
      //                   (key) =>
      //                     key.nama_perusahaan != perusahaan.nama_perusahaan
      //                 )
      return this.$store.state.Auth.data.perusahaan
        .filter((key) => key.nama_perusahaan != this.perusahaan.nama_perusahaan)
        .filter((o) =>
          (o.nama_perusahaan || "")
            .toUpperCase()
            .match(this.search.toUpperCase())
        );
    },
  },

  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    });
  },
  mounted() {
    document.body.classList.remove("ModalCustom");
    this.checkPerusahaan();
    this.fetch({
      page: 1,
      pageSizes: 15,
      id_perusahaan: this.perusahaan.id_job_order_perusahaan,
    });
  },
  methods: {
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    onLeftSearch(e) {
      this.search = e.target.value;
      //console.log(this.search)
      this.FilterSearch;
    },
    checkPerusahaan() {
      let company = {
        id_contact_perusahaan: "all",
        id_job_order_perusahaan: "all",
        nama_perusahaan: "all",
        benefit_existing: "all",
      };
      // this.profile.perusahaan[
      //   Math.floor(Math.random() * this.profile.perusahaan.length)
      // ];

      // this.perusahaan =
      //   this.perusahaan == undefined
      //     ? (localStorage.setItem("company", JSON.stringify(company)); JSON.parse(localStorage.getItem("company")) )
      //     : JSON.parse(localStorage.getItem("company"));

      if (JSON.parse(localStorage.getItem("company")) == undefined) {
        localStorage.setItem("company", JSON.stringify(company));
        this.perusahaan = JSON.parse(localStorage.getItem("company"));
      } else {
        this.perusahaan = JSON.parse(localStorage.getItem("company"));
      }
    },
    Movementclick(item, perusahaan) {
      this.$router.push({
        name: "ListDashboardMovement",
        query: { tipe: item, ...perusahaan },
      });
    },
    dashboardclick(item, perusahaan) {
      this.$router.push({
        name: "ListDashboard",
        query: { tipe: item, ...perusahaan },
      });
    },
    greeting() {
      let ndate = new Date();
      let hours = ndate.getHours();

      let message =
        hours < 10
          ? "Selamat Pagi "
          : hours < 15
          ? "Selamat Siang "
          : hours < 18
          ? "Selamat Sore "
          : "Selamat Malam ";

      return message;
    },
    changePT(id, pt, kontak, benefit_existing) {
      this.perusahaan = {
        id_contact_perusahaan: kontak,
        id_job_order_perusahaan: id,
        nama_perusahaan: pt,
        benefit_existing: benefit_existing,
      };

      localStorage.setItem("company", JSON.stringify(this.perusahaan));

      this.fetch({
        pageSizes: 15,
        id_perusahaan: this.perusahaan.id_job_order_perusahaan,
      });
    },
    handleResize() {
      if (this.loading2) {
        if (this.$refs.KlaimProses != undefined) {
          this.heighttable =
            this.$refs.KlaimProses == undefined
              ? 0
              : this.$refs.KlaimProses.clientHeight - 190;
          this.widthtable =
            this.$refs.KlaimProses == undefined
              ? 0
              : this.$refs.KlaimProses.clientWidth;

          //console.log(this.$refs.KlaimProses);
        }
      }
    },
    sortByPending(name, item) {
      if (this.sortByPendinglabel == "") {
        this.sortByPendinglabel = name;
      }

      if (this.sortByPendinglabel != name) {
        this.sortByPendinglabel = name;
        this.clickPending = 0;
      }

      this.clickPending += 1;

      if (this.clickPending == 3) {
        this.clickPending = 0;
      }

      let sorting = {
        field: item,
        order:
          this.clickPending == 0
            ? undefined
            : this.clickPending == 1
            ? "ascend"
            : "descend",
      };

      this.handleTableChangePending(1, "", sorting);
    },

    sortByProses(name, item) {
      if (this.sortByProseslabel == "") {
        this.sortByProseslabel = name;
      }

      if (this.sortByProseslabel != name) {
        this.sortByProseslabel = name;
        this.clickProses = 0;
      }

      this.clickProses += 1;

      if (this.clickProses == 3) {
        this.clickProses = 0;
      }

      let sorting = {
        field: item,
        order:
          this.clickProses == 0
            ? undefined
            : this.clickProses == 1
            ? "ascend"
            : "descend",
      };

      this.handleTableChangeProses(1, "", sorting);
    },

    sortByPUCL(name, item) {
      if (this.sortByPUCLlabel == "") {
        this.sortByPUCLlabel = name;
      }

      if (this.sortByPUCLlabel != name) {
        this.sortByPUCLlabel = name;
        this.clickPUCL = 0;
      }

      this.clickPUCL += 1;

      if (this.clickPUCL == 3) {
        this.clickPUCL = 0;
      }

      let sorting = {
        field: item,
        order:
          this.clickPUCL == 0
            ? undefined
            : this.clickPUCL == 1
            ? "ascend"
            : "descend",
      };

      this.handleTableChangePucl(1, "", sorting);
    },

    handleTableChangePucl(page, filters, sorter) {
      // if (sorter.field != "periode_awal" || sorter.field != "periode_akhir") {
      //   this.columns[1].customSort = "";
      // }

      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;

      this.loading2 = true;
      this.$store
        .dispatch("getListDashboardPucl", {
          page: 1,
          pageSizes: 15,
          search: this.search,
          sortField: sorter.field,
          sortOrder: sorter.order,
          id_perusahaan: this.perusahaan.id_job_order_perusahaan,
        })
        .then((response) => {
          this.loading2 = false;
          this.totalPUCL = response.data.total;
          this.dataPucl = response.data.data;
        });
    },
    handleTableChangeProses(page, filters, sorter) {
      // if (sorter.field != "periode_awal" || sorter.field != "periode_akhir") {
      //   this.columns[1].customSort = "";
      // }

      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;
      this.loading3 = true;
      this.$store
        .dispatch("getListDashboard", {
          page: 1,
          pageSizes: 15,
          sortField: sorter.field,
          sortOrder: sorter.order,
          id_perusahaan: this.perusahaan.id_job_order_perusahaan,
        })
        .then((response) => {
          this.loading3 = false;
          this.totalKlaimProses = response.data.total;
          this.data = response.data.data;
        });
    },
    handleTableChangePending(page, filters, sorter) {
      // if (sorter.field != "periode_awal" || sorter.field != "periode_akhir") {
      //   this.columns[1].customSort = "";
      // }

      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;

      this.loading1 = true;
      this.$store
        .dispatch("getListDashboardPending", {
          page: 1,
          pageSizes: 15,
          sortField: sorter.field,
          sortOrder: sorter.order,
          id_perusahaan: this.perusahaan.id_job_order_perusahaan,
        })
        .then((response) => {
          this.loading1 = false;
          this.totalPending = response.data.total;
          this.dataPending = response.data.data;
        });
    },
    Information(e) {
      //alert("tes");
      this.$refs.plan.Show(e);
    },
    fetch(params = {}) {
      this.loading1 = true;
      this.loading2 = true;
      this.loading3 = true;
      this.loading4 = true;

      this.$store
        .dispatch("getListDashboard", {
          ...params,
        })
        .then((response) => {
          this.loading3 = false;
          this.totalKlaimProses = response.data.total;
          this.data = response.data.data;
        });

      this.$store
        .dispatch("getListDashboardPending", {
          ...params,
        })
        .then((response) => {
          this.loading1 = false;
          this.totalPending = response.data.total;
          this.dataPending = response.data.data;
        });

      this.$store
        .dispatch("getListDashboardPucl", {
          ...params,
        })
        .then((response) => {
          this.loading2 = false;
          this.totalPUCL = response.data.total;
          this.dataPucl = response.data.data;
        });

      this.$store
        .dispatch("getPeserta", {
          group: this.group,
          id_perusahaan: this.perusahaan.id_job_order_perusahaan,
        })
        .then((response) => {
          this.loading4 = false;
          this.today = response.data.data.today;

          this.dataChart = {
            labels: response.data.data.month.map((key) => Object.keys(key)[0]),
            datasets: [
              {
                label: "Additon",
                backgroundColor: "#80e1b6",
                data: response.data.data.month.map(
                  (key) => Object.values(key)[0].addition
                ),
                stack: "stack 1",

                barPercentage: 0.4,
              },

              {
                label: "Delete",
                backgroundColor: "#ef8886",
                data: response.data.data.month.map(
                  (key) => Object.values(key)[0].delete * -1
                ),
                stack: "stack 1",
                barPercentage: 0.4,
              },
            ],
          };

          // console.log(
          //   response.data.data.month.map(
          //     (key) => Object.values(key)[0].addition
          //   )
          // );
        });
    },
  },
};
</script>

<style scoped>
.zoomdashboard {
  display: flex;

  align-items: flex-start;
}

.zoomdashboard > div:first-child {
  width: 30%;
}

.zoomdashboard > div:last-child {
  width: 70%;
}

.zoomcarddashboard {
  display: flex;
}

.zoomcarddashboard > div {
  width: 50%;
  margin-right: 15px;
}

.zoomcarddashboard > div:last-child {
  margin-right: 0;
}

.zoomperubaanperbulan {
  width: 100%;
  height: calc((100vh - (2rem + 1.5rem + 2.5rem)) / 2);
}

.dashboarddm {
  display: flex;
  height: calc(35% - 15px);
}
</style>
